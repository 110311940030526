<template>
    <div class="table">
        <div :class="['topbar', { expanded: isExpanded }]">
            <div class="sales-information">
                <button class="nav sales-button" :class="{ active: isExpanded }" @click="toggleSalesSection"></button>
                <button class="nav refresh" @click="refresh"></button>
            </div>
            <div class="content">
                <div class="navBar">
                    <div class="nav-elements">
                        <button class="printer" @click="refresh"></button>
                        <div class="timeframe">
                            <span class="title">Mañana</span>
                            <span class="description">Apertura - 16.00h</span>
                        </div>
                    </div>
                    <div class="nav-elements">
                        <button class="printer" @click="refresh"></button>
                        <div class="timeframe">
                            <span class="title">Tarde</span>
                            <span class="description">16.00h - cierre</span>
                        </div>
                    </div>
                    <div class="nav-elements">
                        <button class="printer" @click="refresh"></button>
                        <div class="timeframe">
                            <span class="title">Total</span>
                            <span class="description">Todo el día</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-sections">
            <div v-if="isExpanded" class="sales-section">
                <div class="sales-text">
                    <span> {{ $t('production.sales.projected') }}</span>
                    <span> {{ $t('production.sales.real') }}</span>
                    <span> {{ $t('production.sales.difference') }}</span>
                    <span> {{ $t('production.sales.percentage_difference') }}</span>
                </div>
                <div class="sales-content">
                    <div v-if="forecast.sales_forecast" class="sales-forecast" ref="scrollContainer3">
                        <div class="row sales-forecast-projected">
                            <span>{{ summary_hours.forecast.morning }}€</span>
                            <span>{{ summary_hours.forecast.afternoon }}€</span>

                            <span v-if="forecast.sales_forecast">{{ summary.forecast }} €</span>
                        </div>
                        <div class="row sales-forecast-real">
                            <span v-if="summary_hours.actual.morning"> {{ summary_hours.actual.morning }}€ </span>
                            <span v-else>-</span>
                            <span v-if="summary_hours.actual.afternoon"> {{ summary_hours.actual.afternoon }}€ </span>
                            <span v-else>-</span>

                            <span v-if="forecast.sales_forecast">{{ summary ? summary.current_sales : 0 }} €</span>
                        </div>
                        <div class="row sales-forecast-difference">
                            <span v-if="summary_hours.diff.morning" :class="{ positive: summary_hours.diff.morning > 0, negative: summary_hours.diff.morning < 0 }"> {{ summary_hours.diff.morning }}€ </span>
                            <span v-else>-</span>
                            <span v-if="summary_hours.diff.afternoon" :class="{ positive: summary_hours.diff.afternoon > 0, negative: summary_hours.diff.afternoon < 0 }"> {{ summary_hours.diff.afternoon }}€ </span>
                            <span v-else>-</span>

                            <span v-if="forecast.sales_forecast" :class="{ positive: summary.diff > 0, negative: summary.diff < 0 }"> {{ summary ? summary.diff : '-' }} € </span>
                        </div>
                        <div class="row sales-forecast-difference-percentatge">
                            <span v-if="summary_hours.diff_delta.morning" :class="{ positive: summary_hours.diff_delta.morning > 0, negative: summary_hours.diff_delta.morning < 0 }"> {{ summary_hours.diff_delta.morning }}% </span>
                            <span v-else>-</span>
                            <span v-if="summary_hours.diff_delta.afternoon" :class="{ positive: summary_hours.diff_delta.afternoon > 0, negative: summary_hours.diff_delta.afternoon < 0 }"> {{ summary_hours.diff_delta.afternoon }}% </span>
                            <span v-else>-</span>

                            <span v-if="forecast.sales_forecast" :class="{ positive: summary.current_delta > 0, negative: summary.current_delta < 0 }"> {{ summary.current_delta ? summary.current_delta : 0 }}%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-content">
                <div class="products">
                    <div class="item" v-for="product in products" :key="product.id" @click="showProductInfo(product)" :class="{ frost: product.defrost }">
                        <div class="icon" :style="{ backgroundImage: 'url(' + product.image + ')' }"></div>
                        <div class="content">
                            <div class="name">
                                <span>{{ product.name }} </span>
                                <div v-if="product.defrost" class="icon"></div>
                            </div>
                            <div class="container">{{ product.containers[product.active_container] ? product.containers[product.active_container].name : '' }}</div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="forecast">
                        <div class="item" v-for="product in products" :key="product.id" ref="scrollContainer">
                            <span class="value current">{{ getTotal(product.id).morning }}</span>
                            <span class="value">{{ getTotal(product.id).afternoon }}</span>
                            <span class="value">{{ getTotal(product.id).total }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'preparationView',
    data() {
        return {
            isExpanded: false,
            closestTime: null,
            date: moment().format('YYYY-MM-DD')
        }
    },
    props: {
        products: {
            type: Array
        }
    },
    computed: {
        hoursForecast() {
            if (this.forecast.sales_forecast != null) {
                return this.forecast.sales_forecast.general.sales_forecast || {}
            }
            return {}
        },
        forecast() {
            return this.$store.getters['production/getForecast']
        },
        summary() {
            if (this.forecast.summary && this.forecast.sales_forecast) {
                var current_level = false
                var current_forecast = 0

                if (true || (moment(this.date).isSame(moment(), 'day') && this.forecast.pmix_real && this.forecast.pmix_real.levels)) {
                    var key = false
                    if (moment(this.date).isSame(moment(), 'day')) {
                        key = moment(Math.floor(moment().unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    } else {
                        key = moment(Math.floor(moment(this.date).add('days', 1).unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    }

                    for (var idx in this.forecast.sales_forecast.general.sales_forecast) {
                        if (idx < key) {
                            current_forecast += this.forecast.sales_forecast.general.sales_forecast[idx]
                            current_forecast += this.forecast.sales_forecast.delivery.sales_forecast[idx]
                        }
                    }

                    current_forecast = Math.round(current_forecast, 2)
                }

                var current_sales = this.forecast.sales_real ? this.forecast.sales_real.general.total : 0

                return {
                    date: moment(this.forecast.summary.ref_general).isValid() ? moment(this.forecast.summary.ref_general).format('DD/MM/YYYY') : this.forecast.summary.ref_general,
                    date_delivery: moment(this.forecast.summary.ref_delivery).isValid() ? moment(this.forecast.summary.ref_delivery).format('DD/MM/YYYY') : this.forecast.summary.ref_delivery,
                    base: this.forecast.summary.sales_base,
                    forecast: Math.round(this.forecast.summary.total),
                    current_forecast: current_forecast,
                    current_sales: current_sales,
                    diff: current_sales - current_forecast,
                    current: this.forecast.sales_real ? this.forecast.sales_real.general.total : 0,
                    current_delta: Math.round(((current_sales - current_forecast) / current_forecast) * 100)
                }
            }
            return {}
        },
        summary_hours() {
            var result = {
                forecast: { morning: 0, afternoon: 0 },
                actual: { morning: 0, afternoon: 0 },
                diff: { morning: 0, afternoon: 0 },
                diff_delta: { morning: 0, afternoon: 0 }
            }

            var key = false
            if (moment(this.date).isSame(moment(), 'day')) {
                key = moment(Math.floor(moment().unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
            } else {
                key = moment(Math.floor(moment(this.date).add('days', 1).unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
            }

            if (this.forecast.summary && this.forecast.sales_forecast) {
                const salesForecast = this.forecast.sales_forecast.general.sales_forecast
                const forecast = { morning: 0, afternoon: 0 }
                const current = { morning: 0, afternoon: 0 }
                const diff = { morning: 0, afternoon: 0 }
                const diff_delta = { morning: 0, afternoon: 0 }

                for (var idx in salesForecast) {
                    const isMoorning = moment(idx).isBefore(moment('16:30', 'H:mm'))

                    if (isMoorning) {
                        forecast.morning += Math.round(this.forecast.sales_forecast.general.sales_forecast[idx] + this.forecast.sales_forecast.delivery.sales_forecast[idx])
                        if (idx < key) {
                            current.morning += this.forecast.sales_real ? this.forecast.sales_real.general[idx] : 0
                            diff.morning += current - forecast
                            diff_delta.morning += (diff / forecast) * 100
                        }
                    } else {
                        forecast.afternoon += Math.round(this.forecast.sales_forecast.general.sales_forecast[idx] + this.forecast.sales_forecast.delivery.sales_forecast[idx])
                        if (idx < key) {
                            current.afternoon += this.forecast.sales_real ? this.forecast.sales_real.general[idx] : 0
                            diff.afternoon += current - forecast
                            diff_delta.afternoon += (diff / forecast) * 100
                        }
                    }

                    result.forecast = forecast
                    result.actual = current
                    result.diff = diff
                    result.diff_delta = diff_delta
                }
            }
            return result
        }
    },
    methods: {
        getTotal(productId) {
            //forecast.products[product.id].preparation.morning
            var product = this.products.find((item) => item.id === productId)
            var units = product.containers[product.active_container].units
            var factor = 0.25
            if (this.forecast && this.forecast.products) {
                return {
                    total: Math.ceil(this.forecast.products[productId].total / units / factor) * factor,
                    morning: Math.ceil(this.forecast.products[productId].preparation.morning / units / factor) * factor,
                    afternoon: Math.ceil(this.forecast.products[productId].preparation.afternoon / units / factor) * factor
                }
            }

            return null
        },
        calculateTotalForecast(product) {
            if (!this.forecast || !this.forecast.products) {
                return 0
            }
            const forecast = this.forecast.products[product.id] || {}
            const hoursForecast = this.hoursForecast
            var units = product.containers[product.active_container].units
            var totalForecast = 0

            for (const date in hoursForecast) {
                var key = moment(date).format('HH:mm')
                const factor = 0.25
                const forecastValue = forecast[key] !== undefined ? Math.ceil(forecast[key] / units / factor) * factor : 0
                if (moment(date).isSameOrBefore(this.closestTime)) {
                    totalForecast += forecastValue
                }
            }

            return totalForecast
        },
        calculateClosestHour() {
            const nowMinutes = new Date().getMinutes()
            const nowHours = new Date().getHours()

            let closestTime
            if (nowMinutes < 30) {
                closestTime = new Date(new Date().setHours(nowHours, 0, 0, 0))
            } else {
                closestTime = new Date(new Date().setMinutes(30, 0, 0))
            }

            this.closestTime = closestTime
            for (let key in this.hoursForecast) {
                const forecastDate = new Date(key)
                if (forecastDate <= closestTime) {
                    this.closestKey = key
                } else {
                    break
                }
            }
        },
        timeConvertor(date) {
            return moment(date).format('HH:mm')
        },
        toggleSalesSection() {
            this.isExpanded = !this.isExpanded
            /* var scrollTo = this.$refs.scrollContainer[0].scrollLeft
            this.$nextTick(() => {
                this.$refs.scrollContainer3.scrollTo({ left: scrollTo, behavior: 'smooth' })
            }) */
        },
        refresh() {
            this.isExpanded = false
            this.loadForecast()
        },
        loadForecast() {
            let self = this
            this.$overlay.loading()
            this.$store.dispatch('production/loadForecast', this.date).then(() => {
                self.$overlay.hide()
                self.calculateClosestHour()
                self.scrollToClosestHour()
            })
        },
        scrollLeft() {
            this.scrollContainers(-130)
        },
        scrollRight() {
            this.scrollContainers(130)
        },
        scrollContainers(offset) {
            this.$refs.scrollContainer.forEach((container) => {
                container.scrollBy({ left: offset, behavior: 'smooth' })
            })
            this.$refs.scrollContainer1.scrollBy({ left: offset, behavior: 'smooth' })
            if (this.$refs.scrollContainer3) {
                this.$refs.scrollContainer3.scrollBy({ left: offset, behavior: 'smooth' })
            }
        },
        scrollToClosestHour() {
            const scrollItemIndex = Object.keys(this.hoursForecast).findIndex((key) => key === this.closestKey)
            if (scrollItemIndex !== -1) {
                const scrollOffset = scrollItemIndex * 120
                this.$refs.scrollContainer.forEach((container) => {
                    container.scrollTo({ left: scrollOffset, behavior: 'smooth' })
                })
                this.$refs.scrollContainer1.scrollTo({ left: scrollOffset, behavior: 'smooth' })
                this.$refs.scrollContainer3.scrollTo({ left: scrollOffset, behavior: 'smooth' })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.table {
    display: flex;
    flex-direction: column;
    height: 100%;

    .positive {
        font-family: $text-bold !important;
        color: $color-success-500;
    }
    .negative {
        font-family: $text-bold !important;
        color: $color-error-500;
    }

    .topbar {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        overflow: hidden;

        .content {
            display: flex;
            width: 70%;
            justify-content: flex-end;
        }

        button {
            height: 50px;
            width: 50px;
            font-size: 30px;
            background-color: #fff;
        }
        .sales-information {
            width: 30%;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            flex-shrink: 0;
            height: 50px;
            gap: 8px;

            .nav {
                border-radius: 4px;
                display: flex;
                align-self: flex-start;
                width: 50px;
                height: 50px;
                align-items: center;
                &.sales-button {
                    @include background($image: img('chart_inactive.svg'), $size: 20px, $color: #fff);
                }
                &.active {
                    &.sales-button {
                        @include background($image: img('chart_active.svg'), $size: 20px, $color: $main);
                    }
                }
                &.refresh {
                    @include background($image: img('reload.svg'), $size: 20px, $color: #fff);
                }
            }
        }
        .navBar {
            width: calc(100% - 100px);
            display: grid;
            justify-items: start;
            grid-template-columns: repeat(3, 1fr);
            gap: 25px;

            .nav-elements {
                //width: 250px;
                @include display-flex();
                @include align-items();
                gap: 8px;

                .printer {
                    border-radius: 4px;
                    display: flex;
                    align-self: flex-start;
                    width: 50px;
                    height: 50px;
                    align-items: center;
                    @include background($image: img('printer_b3b3b3.svg'), $size: 20px, $color: #fff);
                }
                .timeframe {
                    @include display-flex();
                    flex-direction: column;

                    .title {
                        text-transform: uppercase;
                        color: $color-black;
                        @include font-size(l);
                        font-family: $text-bold;
                    }

                    .description {
                        color: $color-neutral-600;
                        @include font-size(s);
                        font-family: $text;
                    }
                }
            }

            .nav {
                border-radius: 4px;
                width: 50px;
                height: 50px;

                &.left {
                    @include background($image: img('left_dark.svg'), $size: 20px, $color: #fff);
                }
                &.right {
                    @include background($image: img('right_dark.svg'), $size: 20px, $color: #fff);
                }
            }
            .hours {
                width: calc(100% - 100px);
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                overflow-x: hidden;
                scroll-behavior: smooth;
                gap: 25px;
                padding: 0 0px;

                .scroll-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 80px;
                    height: 100%;
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;
                    margin-left: 5px;

                    p {
                        font-family: $text;
                        font-size: 20px;
                        color: $color-neutral-600;
                    }
                }
                & .selected {
                    border: 2px solid $color-warning-500;
                    p {
                        font-family: $text-bold;
                    }
                }
            }
        }
        .summary-title {
            text-align: center;
            width: 125px;
            flex-shrink: 0;

            span {
                word-wrap: break-word;
                @include font-size(m);
                color: $color-neutral-600;
                font-family: $text-bold;
                text-transform: uppercase;
            }
        }
    }
    .content-sections {
        height: 90%;
        overflow: auto;
        .sales-section {
            width: 100%;
            display: flex;
            flex-direction: row;
            .sales-text {
                width: 30%;
                padding-left: 60px;
                span {
                    display: block;
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
            .sales-content {
                width: 70%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                box-sizing: border-box;
                overflow: hidden;
                .sales-forecast {
                    width: calc(100% - 100px);
                    display: grid;
                    flex-direction: column;
                    grid-template-rows: repeat(4, 1fr);
                    align-items: center;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    scroll-behavior: smooth;

                    .row {
                        display: grid;
                        flex-direction: row;
                        gap: 25px;
                        width: 100%;
                        grid-template-columns: repeat(3, 1fr);

                        .scroll-item {
                            flex: 0 0 80px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100px;
                            max-width: 100px;
                            min-width: 100px;
                            margin-left: 5px;

                            span {
                                text-align: center;
                                width: 100px;
                                font-family: $text;

                                &.positive {
                                    font-family: $text-bold;
                                    color: $color-success-500;
                                }
                                &.negative {
                                    font-family: $text-bold;
                                    color: $color-error-500;
                                }
                            }
                        }
                    }
                }
            }
            .sales-summary {
                width: 125px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                span {
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
        }
        .product-content {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-top: 16px;

            .products {
                width: 30%;

                .item {
                    width: 100%;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.6);
                    }
                    &.frost {
                        &:hover {
                            background-color: $main-t90;
                        }
                    }

                    .icon {
                        width: 50px;
                        height: 50px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 90%;
                        display: inline-block;
                    }
                    .content {
                        flex-direction: column;
                        width: 100%;
                        padding-left: 8px;
                        .name {
                            display: flex;
                            align-items: center;
                            font-family: $text-bold;
                            text-transform: uppercase;
                            @include font-size(m);
                        }
                        .icon {
                            /* background-image: img('/img/defrost.svg'); */
                            height: 25px;
                            width: 25px;
                        }
                        .container {
                            display: block;
                            font-family: $text;
                            color: $color-neutral-600;
                            display: block;
                        }
                    }
                }
            }

            .content {
                display: flex;
                width: 70%;
                justify-content: flex-end;

                .forecast {
                    width: calc(100% - 100px);
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: stretch;

                    .item {
                        height: 75px;
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        flex-direction: row;
                        align-items: center;
                        overflow-x: hidden;
                        scroll-behavior: smooth;
                        padding: 0;
                        gap: 25px;
                        width: 100%;

                        .value {
                            font-family: $text;
                            @include font-size(m);
                            display: block;
                            height: 60px;
                            background: #fff;
                            border: 1px solid #ddd;
                            border-radius: 3px;
                            @include display-flex();
                            @include align-items();
                            @include justify-content();
                            position: relative;
                            //width: 250px;

                            .increase {
                                display: block;
                                position: absolute;
                                bottom: 0px;
                                @include font-size(xs);
                                display: flex;
                                align-items: center;

                                &:before {
                                    display: inline-block;
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-radius: 3px;
                                    margin-right: 2px;
                                }

                                &.positive {
                                    &:before {
                                        border-bottom: 6px solid green;
                                    }
                                }
                                &.negative {
                                    &:before {
                                        border-top: 6px solid #f00;
                                    }
                                }
                            }

                            &.current {
                                background-color: $color-warning-500;
                                border-color: $color-warning-500;
                                font-family: $text-bold;
                                color: #fff;

                                .value {
                                    color: #fff;
                                }
                                .increase {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
            .summary {
                width: 125px;
                text-align: center;
                .item {
                    width: 100%;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .value {
                        font-family: $text-bold;
                        @include font-size(l);
                        text-transform: uppercase;
                        text-align: center;
                    }
                }
            }
        }
    }
}

//RBI DEVICE
@media (max-width: 1285px) {
    .table {
        .topbar {
            .navBar {
                .nav-elements {
                    .timeframe {
                        .title {
                            @include font-size(l);
                        }
                        .description {
                            @include font-size(xs);
                        }
                    }
                    .printer {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}
</style>
