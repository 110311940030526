<template>
    <div id="sidebar-container" class="column left hide-scrollbar">
        <div id="calendar">
            <div class="calendar-navigation-container">
                <calendar-navigation @select="selectDate" @calendarOpen="calendarState" :selected_date="selected_date" :future_days="1" :past_days="1"></calendar-navigation>
            </div>
        </div>
        <template v-if="selected">
            <div class="title-column">
                <div class="title">
                    <span>{{$t('production.select.select-day')}}</span>
                </div>
            </div>
        </template>

        <template v-if="expanded">
            <div class="column left hide-scrollbar categories column-withtab" v-if="Object.values(categories).length != 0">
                <!-- Categories -->
                <div class="item all" v-if="sortedCategories" @click="clickCat(false)" :class="{ selected: !category }">
                    <span class="color" v-bind:style="{ backgroundColor: '#475b96' }"></span>
                    <span class="text">{{ $t('label.categories.all') }}</span>
                </div>
                <template v-if="sortedCategories">
                    <div
                        v-for="category in sortedCategories"
                        class="item"
                        :class="{ selected: category.selected }"
                        :key="category.id"
                        :style="
                            category.selected
                                ? {
                                      backgroundColor: category.selected ? category.color : 'transparent'
                                  }
                                : {}
                        "
                        @click="clickCat(category)"
                    >
                        <span class="color" v-bind:style="{ backgroundColor: category.color }"></span>
                        <span class="text">{{ category.name }}</span>
                    </div>
                </template>
            </div>

            <div id="summary" v-if="summary">
                <div v-if="summary.date" class="row">
                    {{ $t('production.summary.ref_general_date') }}: <span class="value">{{ summary.date }}</span>
                </div>
                <div v-if="summary.date_delivery" class="row">
                    {{ $t('production.summary.ref_delivery_date') }}: <span class="value">{{ summary.date_delivery }}</span>
                </div>
                <div v-if="summary.base" class="row">
                    {{ $t('production.summary.base') }}: <span class="value">{{ summary.base }}€</span>
                </div>
                <div v-if="summary.forecast" class="row">
                    {{ $t('production.summary.forecast') }}: <span class="value">{{ summary.forecast }}€</span>
                </div>
                <div v-if="summary.current" class="row">
                    {{ $t('production.summary.current') }}: <span class="value">{{ summary.current }}€</span>
                </div>
                <div v-if="summary.current_delta" class="row current">
                    {{ $t('production.summary.current_sales') }}: <span class="value" :class="{ positive: summary.current_delta > 0, negative: summary.current_delta < 0, neutral: summary.current_delta == 0 }">{{ summary.current_delta }}%</span>
                </div>
                <!-- <div v-if="summary.level" class="row level">
                    {{ $t('production.summary.level') }}: <span class="value">{{ summary.level }}</span>
                </div> -->

                <div class="reset" @click="resetForecast()">{{ $t('production.summary.reset') }}</div>

                <button class="btn search" @click="updateOption()">
                    {{ $t('production.configuration.button') }}
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import CalendarNavigation from '@/components/layout/CalendarNavigation.vue'

export default {
    name: 'Sidebar',
    props: { categories: { type: Object }, date: { type: String }, summary: { type: Object }, expanded: { type: Boolean }, selected: { type: Boolean} },
    components: {
        CalendarNavigation
    },
    data() {
        return {
            selected_date: this.date,
            categorySelected: false,
            calendarIsVisible: false
        }
    },
    computed: {
        sortedCategories() {
            return Object.values(this.categories).sort((a, b) => parseInt(a.order) - parseInt(b.order))
        },
        category() {
            return this.$store.getters['production/getSelectedCategory']
        }
    },
    methods: {
        selectCategory(category) {
            this.categorySelected = category
        },
        selectDate(date) {
            this.$emit('selectDate', date)
        },
        calendarState(show) {
            this.calendarIsVisible = show
        },
        clickCat(category) {
            var container = document.getElementsByClassName('categories-container')[0]
            if (container) container.scrollTop = 0

            if (category !== 'allCategories') {
                this.allCategories = false
                this.catSelPortrait = false
                if (this.cantSelectCategoryPrinterError) {
                    return false
                } else {
                    if (this.activePredef) {
                        this.activePredef = false
                        this.selectedLab = {}
                    }
                    if (!this.holdingCat) {
                        this.$store.commit('production/selectCategory', category)
                    } else {
                        this.holdingCat = false
                    }
                    this.stateSelected = false
                }
            } else {
                this.allCategories = true
                this.catSelPortrait = true
            }

            this.debounceSearchInput = ''
            this.searchInput = ''
        },
        updateOption() {
            this.$store.commit('production/setConfiguration', true)
        },
        resetForecast() {
            let self = this

            this.$popup.delete({
                message: self.$t('production.reset.title'), // ¿Quieres eliminar la guía de condimentos actual y volver a generar la proyección seleccionando días de referencia distintos?
                textSave: self.$t('production.reset.yes'), // Sí, eliminar
                textCancel: self.$t('popup.delete.text_cancel'),
                callSave: function () {
                    self.$overlay.loading()
                    self.$store.dispatch('production/resetForecast', self.date).then(() => {
                        self.$store.dispatch('production/loadForecast', self.date).then(() => {
                            self.$popup.close()
                            self.$overlay.hide()
                        })
                    })
                }
            })
        }
    },
    created() {},
    watch: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#sidebar-container {
    padding: 8px;
    height: 100%;
    position: relative;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    $size-bolita: rem(18px);

    #calendar {
        width: 100%;
        height: 50px;
    }
    .title-column {
        height: 100% !important;
        width: 100%;
        display: flex;
        justify-content: center;
        .title {
            margin-top: 5%;
            height: 55px;
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-secondary-100;
            border-radius: 5px;
            span {
                @include font-size(S);
            }
        }
    }

    .column.categories {
        width: 100% !important;
        border-radius: 5px;
        margin: 16px 0;
        max-height: calc(100% - 50px - 30%);
        overflow: auto;
        height: 70%;

        .tab {
            @include font-size(sm);
            color: #888;
            font-family: $text;
            text-align: center;
            margin: 0 auto 0 auto;
            padding: 12px 6px;
            width: 50%;
            height: 40px;
            display: inline-block;

            &.selected {
                color: $main-text;
                border-bottom: 2px solid $main-light;
            }
        }

        .item {
            @include interaction();
            @include display-flex();
            @include align-items();
            @include justify-content(space-between);
            @include font-size(m);
            line-height: 1;
            color: #555;
            min-height: rem(60px);
            padding: 4px 12px;
            margin: 4px 0;
            border-radius: 5px;
            // background-color: #fff;

            .color {
                width: $size-bolita;
                height: $size-bolita;
                border-radius: 100%;
            }

            .text,
            .count {
                display: inline-block;
                font: inherit;
                @include font-size(m);
                font-family: $text;
                line-height: inherit;
                white-space: break-spaces;
                word-break: break-word;
            }

            .text {
                width: calc(100% - #{$size-bolita + rem(10px)});
            }
            .count {
                @include background($color: $main-t80);
                @include border-radius(20px);
                @include font-size(s);
                font-family: $text;
                line-height: 20px;
                min-width: 20px;
                width: auto;
                height: 20px;
                text-align: center;
                padding: 0px 4px;
            }

            @include bgHover($inactive-t90);

            &.selected {
                background-color: $main;

                .text {
                    color: white;
                    font-family: $text-bold;
                }

                .color {
                    filter: brightness(75%);
                }

                @include bgHover($main);
            }

            &.all {
            }

            &:active {
                animation-name: itemHoldCat;
                animation-duration: 125ms;
                animation-delay: 175ms;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
            @include keyframes(itemHoldCat) {
                100% {
                    background-color: #f9f9f9;
                    border-left-color: $accent-dark;
                }
            }
        }

        .collection {
            @include interaction();
            @include display-flex();
            @include align-items();
            @include justify-content(space-between);
            @include font-size(m);
            line-height: 1;
            color: #555;
            min-height: rem(60px);
            padding: 4px 12px;
            margin: 4px;
            border-radius: 5px;

            @include bgHover($inactive-t90);

            &.selected {
                font-family: $text-bold;
                background-color: $main;
                color: #fff;

                @include bgHover($main);
            }
        }
    }

    #summary {
        width: 100%;
        bottom: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .row {
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            justify-content: space-between;
            gap: 25px;
            flex-wrap: nowrap;
            word-break: break-word;
            width: 100%;
            padding: 4px 0;
            @include font-size(s);
            line-height: 100%;

            .value {
                font-family: $text-bold;
                width: 35%;
                text-align: right;
                @include font-size(sm);
            }

            &.level {
                margin-top: 8px;
                .value {
                    padding: 4px 8px;
                    border-radius: 3px;
                    background-color: $color-warning-500;
                }
            }

            &.current {
                margin-top: 8px;
                .value {
                    padding: 4px 8px;
                    border-radius: 3px;
                    color: #fff;
                    width: auto;
                    min-width: 90px;
                    text-align: center;
                    &.positive {
                        background-color: $color-success-500;
                    }
                    &.negative {
                        background-color: $color-error-500;
                    }
                    &.neutral {
                        background-color: $color-neutral-500;
                    }
                }
            }
        }

        .reset {
            text-decoration: underline;
            color: $main;
            font-family: $text-bold;
            cursor: pointer;
            padding: 16px 0;
        }

        .btn {
            width: 100%;
            background-color: $main-t70;
            color: $color-black;
            font-family: $text;
            margin-top: 8px;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped></style>
