<template>
    <div id="production-init">
        <!--Para el dia actual-->
        <div class="channels loaded">
            <div class="title">
                <div class="forecast-selected" >{{ $t('production.select.forecast_selected') + formattedDate(sales.delivery.reference) }}</div>
                <div class="icon"></div>
            </div>
            <div class="content">
                <div class="item">
                    <div class="name">{{$t('production.select.restaurant')}}</div>
                    <template v-if="sales">
                        <div class="chart">
                            <ChartSales :data="sales.general.sales_forecast"></ChartSales>
                        </div>
                        <div class="summary">
                            <div class="sales">
                                {{$t('production.select.sale')}} <span>{{ sales.general.total }}€</span>
                            </div>
                            <div class="reference-date">
                                {{$t('production.select.reference_date')}} <span>{{ formatReferenceDay(sales.general.reference) }}</span>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="item">
                    <div class="name">{{$t('production.select.delivery')}}</div>

                    <template v-if="sales">
                        <div class="chart">
                            <ChartSales :data="sales.delivery.sales_forecast"></ChartSales>
                        </div>
                        <div class="summary">
                            <div class="sales">
                                {{$t('production.select.sale')}} <span>{{ sales.delivery.total }}€</span>
                            </div>
                            <div class="reference-date">
                                {{$t('production.select.reference_date')}} <span>{{ formatReferenceDay(sales.delivery.reference) }}</span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <!--Para el siguiente dia-->
        <div class="channels defrost">
            <div class="forecast-defrost" >{{ $t('production.select.forecast_defrost') + ' ' + formattedDate(defrostSales.general.reference)}}</div>
            <div class="content">
                <div class="item">
                    <div class="name forecastDefrost">{{$t('production.select.restaurant')}}</div>
                    <div class="selector">
                        <select v-model="currentGeneralDefrost" class="input" @change="changeType($event, 'generalDefrost')">
                            <option value="weekday">{{$t('production.select.last')}} {{ weekday }}</option>
                            <option value="custom">{{$t('production.select.select-date')}}</option>
                        </select>
                        <input v-if="currentGeneralDefrost == 'custom'" v-model="dateGeneralDefrost" class="input date" type="date" @change="changeDate($event, 'generalDefrost')" :max="maxDate" :min="minDate" />
                    </div>
                    <template v-if="defrostSales">
                        <div class="chart">
                            <ChartSales :data="defrostSales.general.sales_forecast"></ChartSales>
                        </div>
                        <div class="summary">
                            <div class="sales">
                                {{$t('production.select.sale')}} <span>{{ defrostSales.general.total }}€</span>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="item">
                    <div class="name forecastDefrost">{{$t('production.select.delivery')}}</div>
                    <div class="selector">
                        <select v-model="currentDeliveryDefrost" class="input" @change="changeType($event, 'deliveryDefrost')">
                            <option value="weekday">{{$t('production.select.last')}} {{ weekday }}</option>
                            <option value="custom">{{$t('production.select.select-date')}}</option>
                        </select>
                        <input v-if="currentDeliveryDefrost == 'custom'" v-model="dateDeliveryDefrost" class="input date" type="date" @change="changeDate($event, 'deliveryDefrost')" :max="maxDate" :min="minDate" />
                    </div>

                    <template v-if="defrostSales">
                        <div class="chart">
                            <ChartSales :data="defrostSales.delivery.sales_forecast"></ChartSales>
                        </div>
                        <div class="summary">
                            <div class="sales">
                                {{$t('production.select.sale')}} <span>{{ defrostSales.delivery.total }}€</span>
                            </div>
                        </div>
                    </template>

                </div>
            </div>
            <div class="necessary" v-html="$t('production.select.necessary')"></div>
        </div>

        <div class="btnExit">
            <button class="btn save-changes" :class="{ disabled: !((currentGeneral == 'weekday' || dateGeneral != dateDefault) && (currentDelivery == 'weekday' || dateDelivery != dateDefault)) }" @click="confirmForecast()">{{$t('production.select.confirm')}}</button>
        </div>
    </div>
</template>

<script>
import ChartSales from './chartSales.vue'

export default {
    components: {
        ChartSales
    },
    name: 'ProductionInit',
    props: { date: { type: String } },
    data() {
        return {
            dateDefault: moment(this.date).locale('en').format('dddd').toLowerCase(),
            dateGeneral: moment(this.date).locale('en').format('dddd').toLowerCase(),
            dateDelivery: moment(this.date).locale('en').format('dddd').toLowerCase(),
            dateDefaultDefrost: moment(this.date).add(1, 'days').locale('en').format('dddd').toLowerCase(),
            dateGeneralDefrost: moment(this.date).subtract(6, 'days').format('YYYY-MM-DD'), 
            dateDeliveryDefrost: moment(this.date).subtract(6, 'days').format('YYYY-MM-DD'), 
            weekday: moment(this.date).locale('en').format('dddd').toLowerCase(),
            maxDate: moment().format('YYYY-MM-DD'),
            minDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
            currentGeneral: 'weekday',
            currentDelivery: 'weekday',
            currentGeneralDefrost: 'custom',
            currentDeliveryDefrost: 'custom',
            forecastLoaded: null
        }
    },
    computed: {
        sales() {
            console.log('sales', this.$store.getters['production/getSales'])
            return this.$store.getters['production/getSales']
        },
        defrostSales() {
            return this.$store.getters['production/getDefrostSales']
        }
    },
    created() {
        this.load()
    },
    methods: {
        load() {
            let self = this
            this.$overlay.loading()
            var params = {
                date1: this.dateGeneral,
                date2: this.dateDelivery
            }
            this.$store.dispatch('production/loadSales', params)
            var defrostParams = {
                date1: this.dateGeneralDefrost,
                date2: this.dateDeliveryDefrost
            }
            this.$store.dispatch('production/loadDefrostSales', defrostParams).then(function() {
                self.$overlay.hide()
            })
        },
        changeType(e, channel) {
            if (e.target.value == 'weekday') {
                if (channel == 'general') {
                    this.dateGeneral = this.dateDefault
                } else if (channel == 'delivery') {
                    this.dateDelivery = this.dateDefault
                } else if (channel == 'generalDefrost') {
                    this.dateGeneralDefrost = this.dateDefaultDefrost
                } else if (channel == 'deliveryDefrost') {
                    this.dateDeliveryDefrost = this.dateDefaultDefrost
                }
                this.load()
            }
        },
        changeDate(e, channel) {
            if (channel == 'generalDefrost') {
                this.dateGeneralDefrost = e.target.value
                if (this.dateDeliveryDefrost == this.dateDefaultDefrost) {
                    this.currentDeliveryDefrost = 'custom'
                    this.dateDeliveryDefrost = this.dateGeneralDefrost
                }
            } else if (channel == 'deliveryDefrost') {
                this.dateDeliveryDefrost = e.target.value
            }

            this.load()
        },
        confirmForecast() {
            this.$popup.projection();
            const popupTimer = setTimeout(() => {
                this.$popup.close()
                this.$popup.projectionReturn(); 
            }, 40000)
            let self = this
            var data = {
                ref: this.date,
                params: {
                    general: this.dateGeneral,
                    delivery: this.dateDelivery
                }
            }
            this.$store.dispatch('production/confirm', data).then(() => {
                console.warn('dispatch coinfirm')
                self.$store.dispatch('production/loadForecast', self.date).then(() => {
                    clearTimeout(popupTimer)
                    self.$popup.close()
                })
            })
        },
        formatDate(date) {
            if (this.isValidDate(date)) {
                return moment(date).locale(localStorage.language).format('dddd').toUpperCase() + ' ' + moment(date).format('DD/MM');
            } else {
                const localWeekdays = moment.weekdays();
                const englishWeekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                
                const weekdayIndex = englishWeekdays.indexOf(date);

                return localWeekdays[weekdayIndex].toUpperCase();
            }
        },
        isValidDate(dateString) {
            return moment(dateString, 'YYYY-MM-DD', true).isValid();
        },
        formattedDate(date) {
            return this.formatDate(date);
        },
        formatReferenceDay(date) {
            const localWeekdays = moment.weekdays();
            const englishWeekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            
            const weekdayIndex = englishWeekdays.indexOf(date);

            return localWeekdays[weekdayIndex].toLowerCase();
        }
    },
    watch: {
        date(newValue, oldValue) {
            if (newValue != oldValue) {
                this.dateDefault = moment(newValue).locale('en').format('dddd').toLowerCase()
                this.dateGeneral = moment(newValue).locale('en').format('dddd').toLowerCase()
                this.dateDelivery = moment(newValue).locale('en').format('dddd').toLowerCase()
                this.weekday = moment(newValue).locale('en').format('dddd').toLowerCase()
                this.dateDefaultDefrost = moment(this.date).add(1, 'days').locale('en').format('dddd').toLowerCase(),
                this.dateGeneralDefrost = moment(this.date).subtract(6, 'days').format('YYYY-MM-DD'), 
                this.dateDeliveryDefrost = moment(this.date).subtract(6, 'days').format('YYYY-MM-DD'), 
                this.load()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
#production-init {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    overflow: hidden;
    white-space: nowrap;

    .title {
        font-family: $text-bold;
        @include font-size(l);
        margin-bottom: 64px;
        text-align: center;
        margin: 0.8rem 0 1rem 0;

    }
    .channels {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 48%;
        &.loaded {
            background-color: $color-primary-100;
            margin-bottom: 0.7em;
            height: 43%;
        }
        &.defrost {
            border: 1px solid $color-neutral-300;
        }
        .forecast-defrost,
        .necessary,
        .forecast-selected{
            @include font-size(m);
            width: 100%;
            text-align: center;
            font-family: $text;
        }
        .necessary {
            @include font-size(s);
            padding: 0;
            margin: 0;
        }
        .title {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0;
            margin: 0;
            margin-top: 8px;
            .forecast-selected {
                color: $color-primary-500;
                width: auto;
            }
            .icon {
                margin-left: 5px;
                background-image: url('../../../../public/img/check-circle.svg');;
                height: 1.125rem;
                width: 1.125rem;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        .forecast-defrost {
            padding-top: 8px    ;
            color: $color-secondary-500;
        }
        
        .content {
            width: 100%;
            display: flex;
            flex-direction: row;
            padding-bottom: 0;
            .item {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 8px 4px;
                padding-bottom: 0;
                padding-top: 4px;
                align-items: center;

                .name {
                    font-family: $text-bold;
                    @include font-size(s);
                    margin-bottom: 0px;
                    text-align: center;
                    text-transform: uppercase;
                    &.forecastDefrost {
                        margin-bottom: 8px
                    }
                }
                .selector {
                    width: 93%;
                    height: 25px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    &.weekday {
                        justify-content: center;
                    }

                    .input {
                        /* display: flex;
                        flex-direction: column;  */
                        width: 45%;
                        padding: 0;
                        padding-left: 12px;
                        border: 1px solid #ccc;
                        border-radius: 3px;
                        color: $color-black;
                        justify-content: center;
                        line-height: 20px;
                    }
                }
                .summary {
                    margin-top: 8px;

                    .sales,
                    .reference-date{
                        font-family: $text;
                        text-align: center;
                        span {
                            font-family: $text-bold;
                            @include font-size(s);
                        }
                    }
                }
                .chart {
                    width: 93%;
                    height: 144px;
                    background-color: #fff;
                    border-radius: 3px;
                    float: none;
                    margin-top: 8px;
                    overflow: hidden;
                }
            }
        }
    }

    .btnExit {
        height: 10%;
        margin-top: 8px;
        .btn {
            padding: 0px;
            &.save-changes {
                height: 70%;
            }
        }
    }
}
</style>